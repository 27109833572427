<template>
	<div>
		<b-card class="mr-1 p-1" no-body>
			<b-row>
				<b-col class="d-flex align-items-center mb-1 mb-md-0" cols="12" md="6">
					<b-nav pills class="m-0" style="gap: 1rem">
						<b-nav-item
							:to="{ name: 'nursery-sr-specimens' }"
							tag="li"
							active-class="nav-link active active-item-nav"
							link-classes="active-item-nav"
						>
							Ejemplares
							<b-badge
								class="ml-05"
								variant="danger"
								v-if="
									counters.specimens &&
									counters.specimens[0].counter + counters.specimens[1].counter != 0
								"
							>
								{{ counters.specimens[0].counter + counters.specimens[1].counter }}
							</b-badge>
						</b-nav-item>
						<b-nav-item
							:to="{ name: 'nursery-sr-chicks' }"
							tag="li"
							active-class="nav-link active active-item-nav"
							link-classes="active-item-nav"
						>
							Pollos
							<b-badge
								class="ml-05"
								variant="danger"
								v-if="counters.chicks && counters.chicks[0].counter + counters.chicks[1].counter != 0"
							>
								{{ counters.chicks[0].counter + counters.chicks[1].counter }}
							</b-badge>
						</b-nav-item>
					</b-nav>
				</b-col>
				<b-col class="d-flex justify-content-md-end align-items-center" cols="12" md="6">
					<h5 class="m-0 text-nowrap">
						TOTAL EN ESPERA:
						<b v-if="counters.specimens && counters.chicks">
							{{ (counters.specimens[0].counter || 0) + (counters.chicks[0].counter || 0) }}
						</b>
						<b class="mx-1" v-else>Calculando...</b>
					</h5>
				</b-col>
			</b-row>
		</b-card>
		<b-card class="p-1" no-body>
			<router-view
				:action="childAction"
				:counters="counters"
				@actionDone="childAction = ''"
				@refresh="getCounters"
				@blocked="actionBlocked = true"
			/>
		</b-card>
	</div>
</template>

<script>
import nurseryService from "@/services/nursery/default.service"

export default {
	data: () => ({
		// esto esta por si en algun momento se necesitan acciones
		childAction: "",
		actionBlocked: false,
		//
		counters: {},
	}),
	methods: {
		async getCounters() {
			const { data } = await nurseryService.getCounters()
			this.counters = data
		},
	},
	watch: {
		"$route.name"() {
			this.actionBlocked = false
		},
	},
}
</script>
